import React, {Component} from 'react';
import Header from './Navigattion/Header';
import Footer from './Navigattion/Footer';
import { Seo } from '../helpers/Seo';
import { Translation } from 'react-i18next';


class Home extends Component {
    render() {
        return (
            <div>
              <Seo
                  title="Pret personnel 100 approuvé sans document | Argent Aujourd'hui Canada."
                  description="Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des données personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant."
                  type="webapp"
                  name="POLITIQUE DE CONFIDENTIALITÉ"
              />
              <Header />
              <br/><br/>
                <main id="main">
                   <div>
                       <section className="about">
                  <div className="container" data-aos="fade-up">
                    <div className="section-title">
                      <h2><Translation>{(t) => <span>{t("p_title")}</span>}</Translation></h2>
                      <h5>argentaujourdhui.com</h5>
                      <h5>argent Aujourd'hui</h5>
                      <h5><Translation>{(t) => <span>{t("p_type")}</span>}</Translation></h5>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title1")}</span>}</Translation></h3>
                        <p>  <Translation>{(t) => <span>{t("p_text1")}</span>}</Translation> </p>
                        <Translation>{(t) => <span>{t("p_text2")}</span>}</Translation>
                        <p><Translation>{(t) => <span>{t("p_text3")}</span>}</Translation></p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title17")}</span>}</Translation></h3>
                        <p>  <Translation>{(t) => <span>{t("p_text4")}</span>}</Translation> </p>
                        <p><Translation>{(t) => <span>{t("p_text5")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text6")}</span>}</Translation></p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title3")}</span>}</Translation></h3>
                        <p>  <Translation>{(t) => <span>{t("p_text7")}</span>}</Translation> </p>
                        <Translation>{(t) => <span>{t("p_text8")}</span>}</Translation>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title4")}</span>}</Translation></h3>
                        <h3><Translation>{(t) => <span>{t("p_title2")}</span>}</Translation></h3>
                        <p>  <Translation>{(t) => <span>{t("p_text9")}</span>}</Translation> </p>
                         
                        <p>  <Translation>{(t) => <span>{t("p_text9_1")}</span>}</Translation> </p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title5")}</span>}</Translation></h3>
                        <p><Translation>{(t) => <span>{t("p_text10")}</span>}</Translation></p>
                        <p> <Translation>{(t) => <span>{t("p_text11")}</span>}</Translation> </p>
                        <Translation>{(t) => <span>{t("p_text12")}</span>}</Translation>
                        <p><Translation>{(t) => <span>{t("p_text13")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text14")}</span>}</Translation></p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title6")}</span>}</Translation></h3>
                        <h3><Translation>{(t) => <span>{t("p_title7")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text15")}</span>}</Translation> </p>
                        <h3><Translation>{(t) => <span>{t("p_title8")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text16")}</span>}</Translation> </p>
                        <p><Translation>{(t) => <span>{t("p_text17")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text17-a")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text17-b")}</span>}</Translation></p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title9")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text19")}</span>}</Translation></p>
                        <Translation>{(t) => <span>{t("p_text20")}</span>}</Translation>
                        <p><Translation>{(t) => <span>{t("p_text21")}</span>}</Translation></p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title10")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text22")}</span>}</Translation></p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title11")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text23")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text24")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text25")}</span>}</Translation></p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title12")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text26")}</span>}</Translation></p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title13")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text27")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text28")}</span>}</Translation></p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title14")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text29")}</span>}</Translation></p>
                        <p>Nicolas Oulerich</p>
                        <p>info@argentaujourdhui.com</p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title15")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text30")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text31")}</span>}</Translation></p>
                        <h5><Translation>{(t) => <span>{t("p_subtitle1")}</span>}</Translation></h5>
                        <p><Translation>{(t) => <span>{t("p_text32")}</span>}</Translation></p>
                        <h5><Translation>{(t) => <span>{t("p_subtitle2")}</span>}</Translation></h5>
                        <p><Translation>{(t) => <span>{t("p_text33")}</span>}</Translation></p>
                        <h5><Translation>{(t) => <span>{t("p_subtitle3")}</span>}</Translation></h5>
                        <p><Translation>{(t) => <span>{t("p_text34")}</span>}</Translation></p>
                        <p><Translation>{(t) => <span>{t("p_text35")}</span>}</Translation></p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3><Translation>{(t) => <span>{t("p_title16")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("p_text40")}</span>}</Translation></p>
                        <h5><Translation>{(t) => <span>{t("p_subtitle4")}</span>}</Translation></h5>
                        <p><Translation>{(t) => <span>{t("p_text36")}</span>}</Translation> <a href="https://tools.google.com/dlpage/gaoptout/">ici.</a></p>
                        <h5><Translation>{(t) => <span>{t("p_subtitle5")}</span>}</Translation></h5>
                        <p><Translation>{(t) => <span>{t("p_text37")}</span>}</Translation></p>
                        <h5><Translation>{(t) => <span>{t("p_subtitle6")}</span>}</Translation></h5>
                        <p><Translation>{(t) => <span>{t("p_text38")}</span>}</Translation></p>
                        <p>info@argentaujourdhui.com</p>
                        <p><Translation>{(t) => <span>{t("p_text39")}</span>}</Translation> le 30 juillet 2020</p>
                      </div>
                    </div>

                    

                    
                  </div>
                </section>
                
      

         
    </div>
                </main>
              <Footer />
            </div>
          );
    }
}
export default  Home;

